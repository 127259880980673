import React from "react";
import { cartItemModel, userModel } from "../../../Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../Store/Redux/store";
import {
  removeFromCart,
  updateQuantity,
} from "../../../Store/Redux/shoppingCartSlice";
import { useUpdateShoppingCartMutation } from "../../../Apis/shoppingCartApi";

const CartSummary = () => {
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  const dispatch = useDispatch();
  const [updateShoppingCart] = useUpdateShoppingCartMutation();
  const shoppingCartFormStore: cartItemModel[] = useSelector(
    (state: RootState) => state.shoppingCartStore.cartItems ?? []
  );
  if (shoppingCartFormStore == null) {
    return <div>Shopping Cart Empty!!</div>;
  }
  const handleQuantity = (updateQntyBy: number, cartItem: cartItemModel) => {
    if ((updateQntyBy == -1 && cartItem.quantity == 1) || updateQntyBy == 0) {
      updateShoppingCart({
        menuItemId: cartItem.menuItem?.id,
        updateQuantityBy: 0,
        userId: userData.id,
      });
      // remove the item
      dispatch(removeFromCart({ cartItem, quantity: 0 }));
    } else {
      // update the quantity with the new qunatity
      updateShoppingCart({
        menuItemId: cartItem.menuItem?.id,
        updateQuantityBy: updateQntyBy,
        userId: userData.id,
      });
      dispatch(
        updateQuantity({
          cartItem,
          quantity: cartItem.quantity! + updateQntyBy,
        })
      );
    }
  };
  return (
    <div className="container p-4 m-2">
      <h4 className="text-center text-success">Cart Summary</h4>
      {shoppingCartFormStore.map((cartItem: cartItemModel, index: number) => {
        return (
          <>
            <div
              key={index}
              className="d-flex flex-sm-row flex-column align-items-center custom-card-shadow rounded m-3"
              style={{ background: "ghostwhite" }}
            >
              <div className="p-3">
                <img
                  src={cartItem.menuItem?.image}
                  alt=""
                  width={"120px"}
                  className="rounded-circle"
                />
              </div>

              <div className="p-2 mx-3" style={{ width: "100%" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <h4 style={{ fontWeight: 300 }}>{cartItem.menuItem?.name}</h4>
                  <h4>
                    $
                    {(cartItem.quantity! * cartItem.menuItem!.price).toFixed(2)}
                  </h4>
                </div>
                <div className="flex-fill">
                  <h4 className="text-danger">${cartItem.menuItem!.price}</h4>
                </div>
                <div className="d-flex justify-content-between">
                  <div
                    className="d-flex justify-content-between p-2 mt-2 rounded-pill custom-card-shadow  "
                    style={{
                      width: "100px",
                      height: "43px",
                    }}
                  >
                    <span style={{ color: "rgba(22,22,22,.7)" }} role="button">
                      <i
                        onClick={() => handleQuantity(-1, cartItem)}
                        className="bi bi-dash-circle-fill"
                      ></i>
                    </span>
                    <span>
                      <b>{cartItem.quantity}</b>
                    </span>
                    <span style={{ color: "rgba(22,22,22,.7)" }} role="button">
                      <i
                        onClick={() => handleQuantity(1, cartItem)}
                        className="bi bi-plus-circle-fill"
                      ></i>
                    </span>
                  </div>

                  <button
                    onClick={() => handleQuantity(0, cartItem)}
                    className="btn btn-danger mx-1"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default CartSummary;
