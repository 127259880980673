import React from "react";

const MainLoader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{ height: "4rem", width: "4rem" }}
        className={`spinner-border text-warning`}
      ></div>
    </div>
  );
};

export default MainLoader;
