import React from "react";

const Footer = () => {
  return (
    <div
      className="footer
  fixed-bottom text-center p-3 bg-dark text-white
  "
    >
      &copy;Made By Sujeeb Palikhel
    </div>
  );
};

export default Footer;
